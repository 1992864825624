<script>

export default {
  name: 'TrustedSiteMixin',
  methods: {
    addTrustedSiteScript() {
      const tag = document.createElement('script');
      tag.setAttribute('src', 'https://cdn.ywxi.net/js/1.js');
      document.head.appendChild(tag);
    },
  },
};

</script>

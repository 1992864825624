<template>
  <information-page button-title="אפשר להמשיך" @click="next" img-src="/images/onboarding/ob-boti-intro.png"
                    :button-loading="loading">
    <template v-slot:title>
      אנחנו איתך גם בוואטסאפ
    </template>
    <template v-slot:content>
      <div>
        כחלק מהשירות הבוט שלנו מלווה אותך בהודעות. בכל שלב ניתן לחזור לתהליך ההרשמה דרך הלינק המצורף בוואטסאפ
      </div>
    </template>
  </information-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import InformationPage from './components/InformationPage';

export default {
  name: 'BotiIntro',
  components: {
    InformationPage,
  },
  data() {
    return { loading: false };
  },
  async created() {
    Segment.trackUserGot(`${this.segmentEventPrefix}WhatsappOptInEntered`, { newBrand: true });
    Segment.trackUserGot(`${this.segmentEventPrefix}WhatsappOptInEntered_Marketing`, { newBrand: true });
  },
  computed: {
    ...mapState('session', ['segmentEventPrefix']),
  },
  methods: {
    ...mapActions('onboardingState', ['onboardingPageSubmit']),
    async next() {
      this.loading = true;
      Segment.trackUserInteraction(`${this.segmentEventPrefix}WhatsappOptInConfirmClicked`, { newBrand: true });
      Segment.trackUserInteraction(`${this.segmentEventPrefix}WhatsappOptInConfirmClicked_Marketing`, { newBrand: true });
      await this.onboardingPageSubmit();
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
}
</style>
